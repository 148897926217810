import React, { useState } from 'react';

import './LongevityPage.css'

import longevityChecklistLogo from './images/LongevityLogo.png';
import heroImage from './images/LongevityHero.png';
import qrImage from './images/LongevityQrCode.png';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

const LongevityPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  const handleBurgerClick = (e) => {
    setIsMenuOpen(e.target.checked);
  };

  return (
    <div className="container">
      <div className="glass-header">
        <div className="header-content">
          <div className="logo-container">
            <img
              src={longevityChecklistLogo}
              alt="Longevity Checklist Logo"
              className="logo-image"
              onClick={() => window.scrollTo(0, 0)}
            />
          </div>

          <div className="header-controls">
            <button
              className="qr-button"
              onClick={() => setIsQRModalOpen(true)}
            >
              {/* <QrCode size={24} /> */}
              <span>Download</span>
            </button>

            <label className="burger" htmlFor="burger">
              <input
                type="checkbox"
                id="burger"
                checked={isMenuOpen}
                onChange={handleBurgerClick}
              />
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>

          {isMenuOpen && (
            <div className="dropdown-menu">
              {/* <div className="menu-item" onClick={() => window.scrollTo(0, 0)}>Home</div> */}
              <div
                className="menu-item"
                onClick={() => document.querySelector('.features-section').scrollIntoView({ behavior: 'smooth' })}
              >
                Features
              </div>
              <div
                className="menu-item"
                onClick={() => window.open('https://apps.apple.com/us/app/longevity-checklist/id6737241919?itscg=30200&itsct=apps_box_badge&mttnsubad=6737241919', '_blank')}
              >
                Download
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={isQRModalOpen} onClose={() => setIsQRModalOpen(false)}>
        <div className="qr-container">
          <h2 className="qr-title">Scan to Download</h2>
          <img
            src={qrImage}
            alt="QR Code"
            className="qr-code"
          />
          <p className="qr-description">Scan this QR code with your mobile device to download the app</p>
        </div>
      </Modal>


      <div className="hero-container">
        <div className="hero-wrapper">
          <div className="hero-text-content">
            <h1 className="hero-title">The uncomplicated longevity app</h1>
            <p className="hero-description">
              Health advice everywhere.
              It's complicated and often contradicting.
              But the Longevity Checklist app makes it simple.
              Easy daily habits to follow, personalized to you.
            </p>
            <a
              href="https://apps.apple.com/us/app/longevity-checklist/id6737241919?itscg=30200&itsct=apps_box_badge&mttnsubad=6737241919"
              style={styles.appStoreButtonContainer}
            >
              <img
                src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1730073600"
                alt="Download on the App Store"
                style={styles.appStoreButtonOfficial}
              />
            </a>
          </div>
          <div className="hero-image-container">
            <img
              src={heroImage}
              alt="Longevity Checklist"
              className="hero-image"
            />
          </div>
        </div>

        <div className="features-section">
          <h3 className="features-title">Features</h3>
          <div className="features-cards">
            <div className="feature-card">
              <h4>Daily Tasks</h4>
              <p>Receive curated daily habits and activities that encourage health and well-being. From nutrition tips to exercise suggestions, we've got you covered.</p>
            </div>
            <div className="feature-card">
              <h4>Long-Term Goals</h4>
              <p>Set and track objectives to foster lasting lifestyle changes. Achieve milestones that contribute to a healthier future.</p>
            </div>
            <div className="feature-card">
              <h4>Personalized Recommendations</h4>
              <p>Tailor your checklist based on your preferences and needs. The app adapts to you, ensuring a unique experience.</p>
            </div>
          </div>
          {/* <p className="cta-text">Start Your Longevity Journey Today!</p> */}
        </div>
      </div>

      <footer className="footer">
        <div className="footer-container">
          {/* <div className="footer-section">
            <h4>About</h4>
            <p>The Longevity Checklist app helps you build healthy habits for a longer, better life.</p>
          </div>
          <div className="footer-section">
            <h4>Contact</h4>
            <p>Email: support@longevityapp.com</p>
            <p>Phone: +1 (555) 123-4567</p>
          </div> */}
          {/* <div className="footer-section">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a href="#"><img src="path/to/facebook-icon.png" alt="Facebook" /></a>
              <a href="#"><img src="path/to/twitter-icon.png" alt="Twitter" /></a>
              <a href="#"><img src="path/to/instagram-icon.png" alt="Instagram" /></a>
            </div>
          </div> */}
        </div>
        <p className="footer-credits">Made with Love ❤️</p>
        {/* <p className="footer-credits">© 2025 Tantalum Technology Corp. All Rights Reserved.</p> */}
      </footer>
    </div>
  );
};

const styles = {
  appStoreButtonContainer: {
    marginBottom: '20px',
  },
  appStoreButtonOfficial: {
    width: '170px',
    height: 'auto',
  }
};

export default LongevityPage;

