import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './LongevityPage.css'

import longevityChecklistLogo from './images/LongevityLogo.png';
import qrImage from './images/LongevityQrCode.png';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="modal-close" onClick={onClose}>×</button>
                {children}
            </div>
        </div>
    );
};

const LongevityPrivacyPage = () => {
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isQRModalOpen, setIsQRModalOpen] = useState(false);

    const handleBurgerClick = (e) => {
        setIsMenuOpen(e.target.checked);
    };

    return (
        <div className="container">
            <div className="glass-header">
                <div className="header-content">
                    <div className="logo-container">
                        <img
                            src={longevityChecklistLogo}
                            alt="Longevity Checklist Logo"
                            className="logo-image"
                            onClick={() => window.scrollTo(0, 0)}
                        />
                    </div>

                    <div className="header-controls">
                        <button
                            className="qr-button"
                            onClick={() => setIsQRModalOpen(true)}
                        >
                            {/* <QrCode size={24} /> */}
                            <span>Download</span>
                        </button>

                        <label className="burger" htmlFor="burger">
                            <input
                                type="checkbox"
                                id="burger"
                                checked={isMenuOpen}
                                onChange={handleBurgerClick}
                            />
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>

                    {isMenuOpen && (
                        <div className="dropdown-menu">
                            <div className="menu-item" onClick={() => navigate('/longevitychecklist')}>Home</div>
                            {/* <div
                                className="menu-item"
                                onClick={() => document.querySelector('.features-section').scrollIntoView({ behavior: 'smooth' })}
                            >
                                Features
                            </div> */}
                            <div
                                className="menu-item"
                                onClick={() => window.open('https://apps.apple.com/us/app/longevity-checklist/id6737241919?itscg=30200&itsct=apps_box_badge&mttnsubad=6737241919', '_blank')}
                            >
                                Download
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Modal isOpen={isQRModalOpen} onClose={() => setIsQRModalOpen(false)}>
                <div className="qr-container">
                    <h2 className="qr-title">Scan to Download</h2>
                    <img
                        src={qrImage}
                        alt="QR Code"
                        className="qr-code"
                    />
                    <p className="qr-description">Scan this QR code with your mobile device to download the app</p>
                </div>
            </Modal>

            <div className="privacy-policy-container">
                <h1 className="privacy-policy-title">Privacy Policy for Longevity Checklist</h1>
                <p className="privacy-policy-update-date">Last updated: January 23, 2025</p>

                <section className="privacy-section">
                    <h3 className="privacy-subtitle">Information We Collect</h3>
                    <p className="privacy-text">
                        Longevity Checklist does not currently collect any personal data.
                    </p>
                </section>

                <section className="privacy-section">
                    <h3 className="privacy-subtitle">Your Rights</h3>
                    <p className="privacy-text">
                        Our site may, from time to time, contain links to websites of partner networks, advertisers, or affiliates. If you follow a link to any of these websites, please note that they have their own privacy policies, and we do not accept responsibility for these policies. Please review them before submitting any personal data to these websites.
                    </p>
                </section>

                <section className="privacy-section">
                    <h3 className="privacy-subtitle">Updates to the Privacy Policy</h3>
                    <p className="privacy-text">
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for operational, legal, or regulatory reasons. Any changes we make will be posted on this page.
                    </p>
                </section>

                <section className="privacy-section">
                    <h3 className="privacy-subtitle">Disclaimer</h3>
                    <p className="privacy-text">
                        Longevity Checklist provides general information and guidance to promote healthy living. It is not a substitute for professional medical advice, diagnosis, or treatment. Always consult a qualified healthcare provider for personalized medical advice.
                    </p>
                </section>

                {/* <section className="privacy-section">
                    <h3 className="privacy-subtitle">Contact Information</h3>
                    <p className="privacy-text">
                        If you have questions or concerns about this Privacy Policy or how we handle your data, please contact us at:
                        <br />
                        <a href="mailto:michael@taci.uk" className="privacy-contact-link">michael@taci.uk</a>
                    </p>
                </section> */}
            </div>

            <footer className="footer">
                <div className="footer-container">
                </div>
                <p className="footer-credits">Made with Love ❤️</p>
                {/* <p className="footer-credits">© 2025 Tantalum Technology Corp. All Rights Reserved.</p> */}
            </footer>
        </div>
    );
};

export default LongevityPrivacyPage;